/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import styled, { createGlobalStyle } from "styled-components";

const HoldingLayout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <HoldingLayoutWrapper>
        <Main>{children}</Main>
      </HoldingLayoutWrapper>
    </>
  );
};

HoldingLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    height: 100%;
    font-size: 18px;
    line-height: 1.5;

    @media (min-width: 32em) {
      font-size: 24px;
    }
  }


  body {
    height: 100%;
    margin: 0;
    font-family: Lato, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-top: 1em;
    margin-bottom: 0;
  }

  h1 {
    line-height: 1.2;
  }

`;

const HoldingLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Main = styled.main`
  flex-grow: 1;
  display: flex;
  justify-content: center;

  padding: 2rem;
`;

export default HoldingLayout;
